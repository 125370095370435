export const setBrandingThemeColors = (config?): void => {
  if (location.hostname.includes('building.wago.cloud')) {
    updateTheme();
  }
};

const updateTheme = (): void => {
  updateMainColors();
  updateAccentColors();
  updateDefaultColorPalette();
};

const ColorPaletteToSequenceMap = [0, 2, 7, 4, 6, 1, 3, 8, 5, 9];

export const DefaultColorPalette = ['#3599B8', '#8AD4EB', '#01B8AA', '#AAD97F', '#F2C80F', '#FE9666', '#FD625E', '#A66999', '#DFBFBF', '#5F6B6D'];
export const DefaultColorSequence = ColorPaletteToSequenceMap.map(i => DefaultColorPalette[i]);

const updateDefaultColorPalette = (): void => {
  DefaultColorPalette[0] = '#46738f';
  DefaultColorPalette[1] = '#69addb';
  DefaultColorPalette[2] = '#36b6aa';
  DefaultColorPalette[3] = '#6ec800';
  DefaultColorPalette[4] = '#ffe70a';
  DefaultColorPalette[5] = '#eb600a';
  DefaultColorPalette[6] = '#bb0522';
  DefaultColorPalette[7] = '#bca3ce';
  DefaultColorPalette[8] = '#f29b92';
  DefaultColorPalette[9] = '#626973';

  ColorPaletteToSequenceMap.forEach(
    (paletteIndex: number, sequenceIndex: number) => (DefaultColorSequence[sequenceIndex] = DefaultColorPalette[paletteIndex])
  );
};

const updateMainColors = (): void => {
  const style = document.documentElement.style;

  style.setProperty(`--avelon-theme-light-text`, '#000000');
  style.setProperty(`--avelon-theme-light-icon-hue`, '#000000');
  style.setProperty(`--avelon-theme-light-link`, '#6ec800');
  style.setProperty(`--avelon-theme-dark-link`, '#6ec800');

  style.setProperty(`--avelon-theme-light-A700`, '#bb0522');
  style.setProperty(`--avelon-theme-dark-A700`, '#bb0522');

  style.setProperty(`--avelon-theme-light-hover`, 'rgba(102, 108, 120, 0.1)');
  style.setProperty(`--avelon-theme-dark-hover`, 'rgba(255, 255, 255, 0.1)');
  style.setProperty(`--avelon-theme-light-hover-text`, 'var(--avelon-theme-light-contrast-50)');
  style.setProperty(`--avelon-theme-dark-hover-text`, 'var(--avelon-theme-dark-contrast-400)');
  style.setProperty(`--avelon-theme-light-selected`, '#6ec800');
  style.setProperty(`--avelon-theme-dark-selected`, '#6ec800');
  style.setProperty(`--avelon-theme-light-selected-text`, '#000000');
  style.setProperty(`--avelon-theme-dark-selected-text`, '#000000');
  style.setProperty(`--avelon-theme-light-selected-shape-fill`, 'rgba(110, 200, 0, 0.2)');
  style.setProperty(`--avelon-theme-dark-selected-shape-fill`, 'rgba(110, 200, 0, 0.2)');

  style.setProperty(`--avelon-theme-main-navigation-tint-left`, '#6ec800');
  style.setProperty(`--avelon-theme-main-navigation-tint-text-left`, '#000000');
  style.setProperty(`--avelon-theme-main-navigation-tint-right`, 'transparent');
  style.setProperty(`--avelon-theme-main-navigation-tint-text-right`, 'inherit');
  style.setProperty(`--avelon-theme-light-topbar`, '#eff0f1');
  style.setProperty(`--avelon-theme-dark-topbar`, '#21201e');
  style.setProperty(`--avelon-theme-light-topbar-text`, '#000000');
  style.setProperty(`--avelon-theme-dark-topbar-text`, '#ffffff');
  style.setProperty(`--avelon-theme-users-module`, 'unset');
  style.setProperty(`--avelon-theme-users-module-text`, 'unset');
  style.setProperty(`--avelon-theme-devices-module`, 'unset');
  style.setProperty(`--avelon-theme-devices-module-text`, 'unset');
  style.setProperty(`--avelon-theme-documents-module`, 'unset');
  style.setProperty(`--avelon-theme-documents-module-text`, 'unset');
  style.setProperty(`--avelon-theme-settings-module`, 'unset');
  style.setProperty(`--avelon-theme-settings-module-text`, 'unset');
  style.setProperty(`--avelon-theme-shop-module`, 'unset');
  style.setProperty(`--avelon-theme-shop-module-text`, 'unset');
  style.setProperty(`--avelon-theme-navigation-module`, 'unset');
  style.setProperty(`--avelon-theme-navigation-module-text`, 'unset');

  style.setProperty(`--avelon-theme-light-50`, '#f3f4f4');
  style.setProperty(`--avelon-theme-light-100`, '#eff0f1'); // table headers hovered Neutral 7
  style.setProperty(`--avelon-theme-light-200`, '#ecedee'); // table headers
  style.setProperty(`--avelon-theme-light-300`, '#e8e9eb'); // secondary toolbar selected
  style.setProperty(`--avelon-theme-light-400`, '#e3e4e6'); // secondary toolbar hovered // neutral 15
  style.setProperty(`--avelon-theme-light-500`, '#dedfe1'); // secondary toolbar
  style.setProperty(`--avelon-theme-light-600`, '#dadbde'); // primary toolbar selected
  style.setProperty(`--avelon-theme-light-700`, '#d6d8da'); // primary toolbar hovered
  style.setProperty(`--avelon-theme-light-800`, '#d2d4d7'); // primary toolbar
  style.setProperty(`--avelon-theme-light-900`, '#bbbec3'); // topbar on dashboards
};

const updateAccentColors = (): void => {
  const style = document.documentElement.style;

  style.setProperty(`--avelon-theme-light-blue`, '#1f2837');
  style.setProperty(`--avelon-theme-light-blue-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-dark-blue`, '#1f2837');
  style.setProperty(`--avelon-theme-dark-blue-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-light-message-blue`, '#1f2837');
  style.setProperty(`--avelon-theme-light-message-blue-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-dark-message-blue`, '#1f2837');
  style.setProperty(`--avelon-theme-dark-message-blue-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-light-tint-blue`, 'rgba(31, 40, 55, 0.2)');
  style.setProperty(`--avelon-theme-dark-tint-blue`, 'rgba(31, 40, 55, 0.2)');

  style.setProperty(`--avelon-theme-light-green`, '#6ec800');
  style.setProperty(`--avelon-theme-light-green-contrast`, '#000000');
  style.setProperty(`--avelon-theme-dark-green`, '#6ec800');
  style.setProperty(`--avelon-theme-dark-green-contrast`, '#000000');
  style.setProperty(`--avelon-theme-light-message-green`, '#6ec800');
  style.setProperty(`--avelon-theme-light-message-green-contrast`, '#000000');
  style.setProperty(`--avelon-theme-dark-message-green`, '#6ec800');
  style.setProperty(`--avelon-theme-dark-message-green-contrast`, '#000000');
  style.setProperty(`--avelon-theme-light-tint-green`, 'rgba(110, 200, 0, 0.2)');
  style.setProperty(`--avelon-theme-dark-tint-green`, 'rgba(110, 200, 0, 0.2)');

  style.setProperty(`--avelon-theme-light-yellow`, '#ffe70a');
  style.setProperty(`--avelon-theme-light-yellow-contrast`, '#000000');
  style.setProperty(`--avelon-theme-dark-yellow`, '#ffe70a');
  style.setProperty(`--avelon-theme-dark-yellow-contrast`, '#000000');
  style.setProperty(`--avelon-theme-light-message-yellow`, '#ffe70a');
  style.setProperty(`--avelon-theme-light-message-yellow-contrast`, '#000000');
  style.setProperty(`--avelon-theme-dark-message-yellow`, '#ffe70a');
  style.setProperty(`--avelon-theme-dark-message-yellow-contrast`, '#000000');
  style.setProperty(`--avelon-theme-light-tint-yellow`, 'rgba(255, 231, 10, 0.2)');
  style.setProperty(`--avelon-theme-dark-tint-yellow`, 'rgba(255, 231, 10, 0.2)');

  style.setProperty(`--avelon-theme-light-orange`, '#dc751a');
  style.setProperty(`--avelon-theme-light-orange-contrast`, '#000000');
  style.setProperty(`--avelon-theme-dark-orange`, '#dc751a');
  style.setProperty(`--avelon-theme-dark-orange-contrast`, '#000000');
  style.setProperty(`--avelon-theme-light-message-orange`, '#dc751a');
  style.setProperty(`--avelon-theme-light-message-orange-contrast`, '#000000');
  style.setProperty(`--avelon-theme-dark-message-orange`, '#dc751a');
  style.setProperty(`--avelon-theme-dark-message-orange-contrast`, '#000000');
  style.setProperty(`--avelon-theme-light-tint-orange`, 'rgba(220, 117, 26, 0.2)');
  style.setProperty(`--avelon-theme-dark-tint-orange`, 'rgba(220, 117, 26, 0.2)');

  style.setProperty(`--avelon-theme-light-red`, '#bb0522');
  style.setProperty(`--avelon-theme-light-red-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-dark-red`, '#bb0522');
  style.setProperty(`--avelon-theme-dark-red-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-light-message-red`, '#bb0522');
  style.setProperty(`--avelon-theme-light-message-red-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-dark-message-red`, '#bb0522');
  style.setProperty(`--avelon-theme-dark-message-red-contrast`, '#ffffff');
  style.setProperty(`--avelon-theme-light-tint-red`, 'rgba(187, 5, 34, 0.2)');
  style.setProperty(`--avelon-theme-dark-tint-red`, 'rgba(187, 5, 34, 0.2)');

  style.setProperty(`--avelon-theme-dark-50`, '#222222');
  style.setProperty(`--avelon-theme-dark-100`, '#2b2b2b');
  style.setProperty(`--avelon-theme-dark-200`, '#333333');
  style.setProperty(`--avelon-theme-dark-300`, '#3d3d3d');
  style.setProperty(`--avelon-theme-dark-400`, '#454545');
  style.setProperty(`--avelon-theme-dark-500`, '#4e4e4e');
  style.setProperty(`--avelon-theme-dark-600`, '#575757');
  style.setProperty(`--avelon-theme-dark-700`, '#5f5f5f');
  style.setProperty(`--avelon-theme-dark-800`, '#686868');
  style.setProperty(`--avelon-theme-dark-900`, '#7a7a7a');
};
