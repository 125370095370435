/* istanbul ignore file */
/* tslint:disable */

export enum MonitoredResource {
  DISK_SPACE = 'DiskSpace',
  SSLCERTIFICATES = 'SSLCertificates',
  DATA_EXCHANGE_IMPORT = 'DataExchangeImport',
  DATA_EXCHANGE_EXPORT = 'DataExchangeExport',
  OPEN_VPNCERTIFICATES = 'OpenVPNCertificates'
}
