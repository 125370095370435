import {Provider} from '@angular/core';
import {ApolloLink, InMemoryCache, split} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {createClient} from 'graphql-ws';
import {environment} from '../environments/environment';
import {UserSessionService} from './shared/entities/auth/user-session.service';

const SubscriptionUri = '/subscriptions';

const SubscriptionUrl = (location.protocol === 'http:' ? 'ws://' : 'wss://') + location.host + SubscriptionUri;
const PanelSubscriptionUrl = (location.protocol === 'http:' ? 'ws://' : 'wss://') + location.host + '/panel' + SubscriptionUri;

const retryWait = (retries: number) =>
  new Promise<void>(resolve =>
    setTimeout(
      resolve,
      // base delay between 0 and 5s
      Math.min(retries * 1000, 5000) +
        // add random timeout from 300ms to 3s
        Math.floor(Math.random() * (3000 - 300) + 300)
    )
  );

export const ApolloGraphQLProvider: Provider = {
  provide: APOLLO_OPTIONS,
  useFactory(httpLink: HttpLink, oidcSecurityService: OidcSecurityService) {
    let token: string;
    UserSessionService.getToken() || oidcSecurityService.getAccessToken().subscribe(t => (token = t));
    const auth = setContext(() => ({
      headers: UserSessionService.getHeaderToken(UserSessionService.getToken() || token)
    }));

    const http = ApolloLink.from([auth, httpLink.create({uri: SubscriptionUri})]);

    const wsClient = createClient({
      url: SubscriptionUrl,
      lazy: true,
      lazyCloseTimeout: 10000,
      retryAttempts: Infinity,
      shouldRetry: () => true,
      retryWait: retryWait,
      connectionParams: {
        get accessToken() {
          UserSessionService.getToken() || oidcSecurityService.getAccessToken().subscribe(t => (token = t));
          return UserSessionService.getToken() || token;
        }
      }
    });

    const wsClientPanel = createClient({
      url: PanelSubscriptionUrl,
      lazy: true,
      lazyCloseTimeout: 10000,
      retryAttempts: Infinity,
      shouldRetry: () => true,
      retryWait: retryWait
    });

    const wsLink = new GraphQLWsLink(wsClient);
    const wsPanelLink = new GraphQLWsLink(wsClientPanel);

    const splitWsLinks = split(operation => operation.operationName === 'panelInfo', wsPanelLink, wsLink);

    const errorsLink = onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors) {
        graphQLErrors.map(({message, locations, path}) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    });

    const link = errorsLink.concat(
      split(
        // split based on operation type
        ({query}) => {
          const definition = getMainDefinition(query) as any;
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        splitWsLinks,
        http
      )
    );

    return {
      link,
      cache: new InMemoryCache(),
      connectToDevTools: !environment.production
    };
  },
  deps: [HttpLink, OidcSecurityService]
} as Provider;
