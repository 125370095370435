<alc-dialog-title
  *ngIf="dialogData.panelStatus === PanelStatus.Importing"
  [hideCloseButton]="true"
  [title]="'DEVICE_REGISTRATION.TITLE.IMPORTING_IN_PROGRESS' | transloco"></alc-dialog-title>
<alc-dialog-title
  *ngIf="dialogData.panelStatus === PanelStatus.Error"
  [hideCloseButton]="true"
  [title]="'DEVICE_REGISTRATION.TITLE.IMPORTING_ERROR' | transloco"></alc-dialog-title>
<mat-dialog-content class="mat-typography layout-row layout-align-center-center">
  <alc-panel-server-status [panelStatus]="dialogData.panelStatus" [panelErrors]="dialogData.panelErrors"></alc-panel-server-status>
</mat-dialog-content>
