export const LocalStorageKeys = {
  Chart: {
    LegendHiddenPlotIds: 'Chart.LegendHiddenPlotIds'
  },
  CDP: {
    selectedTool: 'CalculatedDataPoints.SelectedTool'
  },
  DashboardFilterPanel: {
    closed: 'DashboardFilterPanel.Closed',
    selectedTab: 'DashboardFilterPanel.SelectedTab',
    tabSize: 'DashboardFilterPanel.Size',
    groupTagsFilter: 'DashboardFilterPanel.GroupTagsFilter'
  },
  DataPoints: {
    timeObject: 'DataPoints.timeObject'
  },
  GenerateProjectStructure: {
    projectStructureCardSize: 'GenerateProjectStructure.projectStructureCardSize',
    groupsPreviewCardSize: 'GenerateProjectStructure.groupsPreviewCardSize'
  },
  MeterNetwork: {
    selectedTool: 'MeterNetwork.SelectedTool'
  },
  Schematic: {
    adHocChart: 'Schematic.AdHocChart',
    selectedTool: 'Schematic.SelectedTool'
  },
  ShapeEditor: {
    behaviorPanelSize: 'ShapeEditor.BehaviorPanelSize'
  },
  UserSession: {
    // deprecated
    stayLoggedInKey: 'Alcedo7.stayLoggedIn',
    accessTokenKey: 'Alcedo7.Token',
    refreshTokenKey: 'Alcedo7.RefreshToken',

    userGuidKey: 'Alcedo7.UserGUID',
    iotAppSelectedClientIdKey: 'selectedClientId',
    sessionDummyKey: 'sessionDummy',
    urlRefKey: 'urlRef',
    languageChangeKey: 'languageChange'
  },
  Panel: {
    baseSystemUrl: 'Panel.baseSystemUrl',
    baseSystemType: 'Panel.baseSystemType',
    machineIsPanel: 'Panel.machine',
    machinePanelUrl: 'Panel.machine.url'
  }
};
