import {HttpClient} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {TicketStatus} from 'api/alarm';
import {CreateGroupResponseTO, GroupDetailsRequestTO} from 'api/entities';
import {Observable, of} from 'rxjs';
import {map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {apiVersion} from '../../../api-version.constant';
import {ArrayService} from '../../services/array.service';
import {orderByLocale} from '../../services/order-by-locale.service';
import {getCacheHeaders} from '../shared.service';
import {Alcedo7User} from '../user/avelon-user.service';
import {TicketListCacheHelper} from '../widgets/ticket-list/ticket-list.cache-helper';
import {GroupType} from './group-types.constant';
import {GroupsCacheHelper} from './groups.cache-helper';
import {
  CarbonFootprintStatus,
  Group,
  GroupDetails,
  GroupsFilterType,
  GroupTicketsCount,
  RealEstateFilterRequest,
  RealEstateFilterResult
} from './groups.interface';
import {RealEstateCacheHelper} from './real-estate.cache-helper';

@Injectable({providedIn: 'root'})
export class GroupsEntity {
  groupChange$ = new EventEmitter<void>();
  groupsSelectionChange$ = new EventEmitter<number>();
  static PADDING = 16;
  private groups: Observable<Group[]>;

  constructor(private http: HttpClient) {}

  getGroups(): Observable<Group[]> {
    if (this.groups) {
      return this.groups;
    }
    return (this.groups = this.http.get<Group[]>(apiVersion + 'clients/' + Alcedo7User.selectedClient.id + '/groups').pipe(
      switchMap(groups => this.buildGroupsTree(groups)),
      shareReplay(1)
    ));
  }

  getGroupsFlatList(cache?: boolean): Observable<Group[]> {
    return this.http.get<Group[]>(apiVersion + 'clients/' + Alcedo7User.selectedClient.id + '/groups', getCacheHeaders(cache));
  }

  findRealEstate(groupId: number): Observable<Group> {
    return this.getGroups().pipe(
      map((groupList: Group[]) => {
        let group = groupList.find(g => g.id === groupId);
        if (group) {
          group = this.findRealEstateInGroups(group);
          if (group) {
            return group;
          }
        }
        return null;
      })
    );
  }

  createGroup(newGroup: GroupDetailsRequestTO): Observable<CreateGroupResponseTO> {
    return this.http.post<CreateGroupResponseTO>(apiVersion + 'clients/' + Alcedo7User.selectedClient.id + '/groups', newGroup);
  }

  updateGroup(groupId: number, group): Observable<GroupDetails> {
    return this.http.put<GroupDetails>(apiVersion + 'groups/' + groupId, group).pipe(
      tap((updatedGroup: GroupDetails) => {
        TicketListCacheHelper.invalidateSchematicTicketList();
        RealEstateCacheHelper.invalidateRealEstateDetails(groupId);
        this.getGroup(updatedGroup).subscribe(groupDetails => {
          Object.assign(groupDetails, updatedGroup);
          if (updatedGroup.type !== GroupType.Portfolio) {
            delete groupDetails.portfolioBaseYear;
          }
        });
        this.invalidateGroups();
      })
    );
  }

  getGroup(group: Group): Observable<GroupDetails> {
    return this.http.get<GroupDetails>(apiVersion + 'groups/' + group.id).pipe(
      tap(groupDetails => {
        if (group.type) {
          groupDetails.type = group.type;
        }
      })
    );
  }

  deleteGroup(groupId: number): Observable<any> {
    return this.http.delete(apiVersion + 'groups/' + groupId).pipe(tap(() => this.invalidateGroups()));
  }

  pasteGroup(sourceId: number, destinationId: number): Observable<any> {
    return this.http.post(apiVersion + 'groups/' + destinationId + '/copyFrom/' + sourceId, {}).pipe(tap(() => this.invalidateGroups()));
  }

  getRealEstatesWithCarbonFootprint(): Observable<RealEstateFilterResult[]> {
    return this.http.post<RealEstateFilterResult[]>(apiVersion + 'groups/filter', {
      clientIds: [Alcedo7User.selectedClient.id],
      carbonFootprintStatuses: [CarbonFootprintStatus.ENABLED],
      includeTypes: [GroupType.RealEstate],
      dtype: GroupsFilterType.RealEstateQueryFilterTO
    } as RealEstateFilterRequest);
  }

  getTicketsCountByGroupId(groupId: number, cache?: boolean): Observable<GroupTicketsCount> {
    return this.http.post<GroupTicketsCount>(
      apiVersion + 'tickets/countByTicketTypes',
      {
        parentGroupId: groupId,
        includeStatuses: [TicketStatus.OPEN, TicketStatus.REOPENED, TicketStatus.ACKNOWLEDGED, TicketStatus.GONE, TicketStatus.ACKNOWLEDGED_AND_GONE]
      },
      getCacheHeaders(cache, true)
    );
  }

  move(sourceId: number, targetId: number, validFrom: string): Observable<void> {
    return this.http.post<void>(apiVersion + 'groups/move', {sourceId, targetId, validFrom}).pipe(tap(() => this.invalidateGroups()));
  }

  invalidateGroups() {
    GroupsCacheHelper.invalidateGroups();
    RealEstateCacheHelper.invalidateRealEstateOwnerships();
    RealEstateCacheHelper.invalidateRealEstateProperties();
    this.groups = null;
    this.groupChange$.emit();
  }

  private buildGroupsTree(groups: Group[]): Observable<Group[]> {
    const groupsMap = ArrayService.arrayToObject(groups);
    const ids = Object.keys(groupsMap);
    for (const i of ids) {
      const groupNode = groupsMap[i];
      if (!groupNode.children) {
        groupNode.children = [];
      }
      const parentGroup = (groupNode.parentGroup = groupsMap[groupNode.parentId]);
      if (parentGroup?.children) {
        parentGroup.children.push(groupNode);
      } else if (parentGroup) {
        parentGroup.children = [groupNode];
      }
    }
    const root = groups.find(node => !node.parentId);
    if (root) {
      const indexOfRoot = groups.indexOf(root);
      groups.splice(indexOfRoot, 1);
      groups.unshift(root);
      root.level = 0;
      root.levelPadding = GroupsEntity.PADDING / 2;
      this.calculateDepth(root, false);
    }
    return of(groups);
  }

  private calculateDepth(tree: Group, noCollapse: boolean): void {
    tree.children = orderByLocale(tree.children, 'name');
    let i = tree.children.length - 1;
    let group: Group;
    for (; i > -1; i--) {
      group = tree.children[i];
      group.level = tree.level + 1;
      group.levelPadding = tree.levelPadding + GroupsEntity.PADDING;
      if (!noCollapse) {
        group.collapse = true;
      }
      this.calculateDepth(group, noCollapse);
    }
  }

  private findRealEstateInGroups(group: Group): Group {
    if (group && group.type === GroupType.RealEstate) {
      return group;
    } else if (group.parentGroup) {
      return this.findRealEstateInGroups(group.parentGroup);
    }
  }
}
