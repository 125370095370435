import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {NgIf} from '@angular/common';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TranslocoModule} from '@ngneat/transloco';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../decorators/unsubscribe.decorator';

@Component({
  selector: 'alc-dialog-title',
  standalone: true,
  imports: [CdkDrag, CdkDragHandle, MatIconModule, MatButtonModule, MatTooltipModule, TranslocoModule, NgIf],
  templateUrl: './dialog-title.component.html',
  styleUrls: ['./dialog-title.component.scss']
})
export class DialogTitleComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() showTitleTooltip: boolean;
  @Input() closeParameter: any;
  @Input() hideCloseButton: boolean;
  private dialogKeydown$: Subscription;
  private dialogBackdropClick$: Subscription;

  constructor(public dialogRef: MatDialogRef<any>) {}

  ngOnInit(): void {
    this.dialogKeydown$ = this.dialogRef.keydownEvents().subscribe(e => !this.dialogRef.disableClose && e.key === 'Escape' && this.closeDialog());
    this.dialogBackdropClick$ = this.dialogRef.backdropClick().subscribe(() => !this.dialogRef.disableClose && this.closeDialog());
  }

  ngOnDestroy(): void {
    unsubscribe(this);
  }

  closeDialog(): void {
    this.dialogRef.close(this.closeParameter || false);
  }
}
