<ng-container *ngIf="panelStatus === PanelStatus.Unregistered">
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_UNREGISTERED' | transloco"></p>
  </alc-message>
</ng-container>
<ng-container *ngIf="panelStatus === PanelStatus.Registered">
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_REGISTERED' | transloco"></p>
  </alc-message>
</ng-container>
<ng-container *ngIf="panelStatus === PanelStatus.Ready">
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.READY' | transloco"></p>
  </alc-message>
</ng-container>
<ng-container *ngIf="panelStatus === PanelStatus.Integrated">
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_INTEGRATED' | transloco"></p>
  </alc-message>
</ng-container>
<ng-container *ngIf="panelStatus === PanelStatus.Activated">
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.PANEL_ACTIVATED' | transloco"></p>
  </alc-message>
</ng-container>
<ng-container *ngIf="panelStatus === PanelStatus.Importing">
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.IMPORTING_IN_PROGRESS' | transloco"></p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </alc-message>
</ng-container>
<ng-container *ngIf="panelStatus === PanelStatus.Unlinking">
  <alc-message class="info" icon="info">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.UNLINKING_IN_PROGRESS' | transloco"></p>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </alc-message>
</ng-container>
<ng-container *ngIf="panelStatus === PanelStatus.Error">
  <alc-message icon="error" class="error">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.IMPORTING_ERROR' | transloco"></p>
    <ng-container *ngFor="let error of panelErrors">
      <span *ngIf="error === PanelError.BeetleSync">{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.BEETLE_SYNC" | transloco }}</span>
      <span *ngIf="error === PanelError.DatabaseImport">{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.DATABASE_IMPORT" | transloco }}</span>
      <span *ngIf="error === PanelError.FlywayVersionMismatch">{{
        "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.FLYWAY_VERSION_MISMATCH" | transloco
      }}</span>
      <span *ngIf="error === PanelError.General">{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.GENERAL" | transloco }}</span>
      <span *ngIf="error === PanelError.RestoreFailed">{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.RESTORE_FAILED" | transloco }}</span>
      <span *ngIf="error === PanelError.DataPointFlagCalculate">{{
        "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.DATA_POINT_FLAG" | transloco
      }}</span>
    </ng-container>
    <ng-container *ngIf="isPanel">
      <span>{{ "DEVICE_REGISTRATION.MESSAGE.LINK_BASE_SYSTEM_SYNC_MANUALLY" | transloco }} </span><br />
      <a [href]="baseSystemNavigationUrl" target="_blank" rel="noreferrer" mat-flat-button>
        <mat-icon>open_in_new</mat-icon>
        {{ "DEVICE_REGISTRATION.LABEL.LINK_BASE_SYSTEM_SYNC_MANUALLY" | transloco }}</a
      >
    </ng-container>
  </alc-message>
</ng-container>
<ng-container *ngIf="hasNonCriticalErrors && panelStatus !== PanelStatus.Error">
  <alc-message icon="warning" class="warning">
    <p [innerHTML]="'DEVICE_REGISTRATION.MESSAGE.IMPORTING_ERROR' | transloco"></p>
    <ng-container *ngFor="let error of panelErrors">
      <span *ngIf="error === PanelError.BeetleSync">{{ "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.BEETLE_SYNC" | transloco }}</span>
      <span *ngIf="error === PanelError.FlywayVersionMismatch">{{
        "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.FLYWAY_VERSION_MISMATCH" | transloco
      }}</span>
      <span *ngIf="error === PanelError.DataPointFlagCalculate">{{
        "DEVICE_REGISTRATION.MESSAGE.PANEL_IMPORTING_ERROR.DATA_POINT_FLAG" | transloco
      }}</span>
    </ng-container>
  </alc-message>
</ng-container>
