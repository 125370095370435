/* istanbul ignore file */
/* tslint:disable */

export enum TicketStatus {
  OPEN = 'open',
  ACKNOWLEDGED = 'acknowledged',
  CLOSED = 'closed',
  REOPENED = 'reopened',
  GONE = 'gone',
  ACKNOWLEDGED_AND_GONE = 'acknowledged_and_gone',
  EVENT = 'event',
  SUPPRESSED = 'suppressed',
  EVENT_CLOSED = 'event_closed',
  DELETED = 'deleted'
}
