/* istanbul ignore file */
/* tslint:disable */

export enum TicketCommentType {
  INVALID = 'invalid',
  INFORMATION = 'information',
  NOTICE = 'notice',
  WARNING = 'warning',
  ALARM = 'alarm',
  RECURRENCE = 'recurrence',
  EVENT_RECURRENCE = 'eventRecurrence',
  DEVICE_ACKNOWLEDGMENT = 'deviceAcknowledgment',
  ACKNOWLEDGMENT = 'acknowledgment',
  RESOLUTION = 'resolution',
  SUPPRESSION = 'suppression'
}
