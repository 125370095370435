import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
  CountryTO,
  DeploymentType,
  DeviceActivationNameAndTypeTO,
  DeviceActivationNewClientRequestTO,
  DeviceActivationReplaceResponse,
  DeviceActivationResponseTO,
  DeviceConnectionDetailsTO,
  GpsCoordinatesTO,
  RealEstateInVicinityTO,
  RealEstateProductActivationTO,
  ReplaceableDeviceTO,
  ResendEmailRequestTO,
  ResendEmailResponseTO
} from 'api/entities';
import {Observable} from 'rxjs';
import {apiVersion} from '../../../api-version.constant';
import {JSONUtils} from '../../services/json-utils.service';
import {MapKey} from '../../services/map.constant';
import {getCacheHeaders} from '../shared.service';
import {GeoDecodeGoogleService, NewClient} from './device.interface';

@Injectable({providedIn: 'root'})
export class DeviceActivationEntity {
  constructor(private http: HttpClient) {}

  deviceVerification(activationCode: string): Observable<void> {
    return this.http.get<void>(apiVersion + 'deviceActivations/' + activationCode);
  }

  getDeviceList(clientId: number, activationCode: string, cache?: boolean): Observable<ReplaceableDeviceTO[]> {
    return this.http.get<ReplaceableDeviceTO[]>(
      apiVersion + 'clients/' + clientId + '/deviceActivations/' + activationCode + '/devices',
      getCacheHeaders(cache)
    );
  }

  replaceDevice(activationCode: string, replacedLocationId: number): Observable<DeviceActivationReplaceResponse> {
    return this.http.post<DeviceActivationReplaceResponse>(apiVersion + 'deviceActivations/' + activationCode + '/replace/' + replacedLocationId, {});
  }

  getDeviceStatus(activationCode: string, cache?: boolean): Observable<DeviceConnectionDetailsTO> {
    return this.http.get<DeviceConnectionDetailsTO>(
      apiVersion + 'deviceActivations/' + activationCode + '/connectionDetails',
      getCacheHeaders(cache)
    );
  }

  getCountries(): Observable<CountryTO[]> {
    return this.http.get(apiVersion + 'countries') as Observable<CountryTO[]>;
  }

  getGPSFromAddress(address: string): Observable<GeoDecodeGoogleService> {
    return this.http.get<GeoDecodeGoogleService>('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address,
        key: MapKey
      }
    });
  }

  getAddressFromGPS(latlng: string): Observable<GeoDecodeGoogleService> {
    return this.http.get<GeoDecodeGoogleService>('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        latlng,
        key: MapKey
      }
    });
  }

  clientActivate(clientActivationCode: string): Observable<DeviceActivationResponseTO> {
    return this.http.get<DeviceActivationResponseTO>(apiVersion + 'clientActivations/' + clientActivationCode + '/activate');
  }

  clientCreate(client: NewClient): Observable<ResendEmailResponseTO> {
    const clone: DeviceActivationNewClientRequestTO = JSONUtils.cloneWithBlackList(client, ['confirmPassword']);
    return this.http.post<ResendEmailResponseTO>(apiVersion + 'clientActivations', clone);
  }

  getDeviceInfo(activationCode: string): Observable<DeviceActivationNameAndTypeTO> {
    return this.http.get<DeviceActivationNameAndTypeTO>(apiVersion + 'deviceActivations/' + activationCode + '/deviceInfo') as Observable<{
      name: string;
    }>;
  }

  getProximityRealEstates(clientId: number, activationCode: string, latitude?: number, longitude?: number): Observable<RealEstateInVicinityTO[]> {
    const params: GpsCoordinatesTO = {latitude, longitude};
    return this.http.post<RealEstateInVicinityTO[]>(
      apiVersion + 'deviceActivations/' + activationCode + '/clients/' + clientId + '/proximityProperties',
      params
    );
  }

  createOrUpdateRealEstate(clientId: number, params: RealEstateProductActivationTO): Observable<number> {
    return this.http.post<number>(apiVersion + 'clients/' + clientId + '/createOrUpdateRealEstate', params);
  }

  deviceActivate(clientId: number, activationCode: string): Observable<DeviceActivationResponseTO> {
    return this.http.get<DeviceActivationResponseTO>(apiVersion + 'clients/' + clientId + '/deviceActivations/' + activationCode + '/activate');
  }

  resendEmail(deviceActivationCode: string, email: string): Observable<ResendEmailResponseTO> {
    return this.http.put<ResendEmailResponseTO>(apiVersion + 'clientActivations/resendEmail', {
      email,
      activationCode: deviceActivationCode
    } as ResendEmailRequestTO);
  }

  registerPanel(registrationCode: string): Observable<void> {
    return this.http.post<void>(apiVersion + 'panel/init/registration', {registrationCode});
  }

  registerPanelOnBaseSystem(baseSystemType: DeploymentType, baseSystemUrl: string, accessToken: string): Observable<void> {
    return this.http.post<void>(apiVersion + 'panel/init/integration', {baseSystemType, baseSystemUrl, accessToken});
  }

  panelInfo(): Observable<{baseSystemType: DeploymentType; baseSystemUrl: string; activationCode: string; clientId?: number; deviceId?: number}> {
    return this.http.get<{baseSystemType: DeploymentType; baseSystemUrl: string; activationCode: string; clientId?: number; deviceId?: number}>(
      apiVersion + 'panel/init/info'
    );
  }
}
