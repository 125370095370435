import {marker} from '@ngneat/transloco-keys-manager/marker';
import {Translate} from '../../services/translate.service';

// Enum order is based on the group size
export enum GroupType {
  Portfolio = 'Portfolio',
  RealEstate = 'RealEstate',
  Building = 'Building',
  StructureGroup = 'StructureGroup'
}

export const AddressingSystemSegmentGroupType = {
  ...GroupType,
  AmendParent: 'AmendParent'
};

export const GroupTypeIcons = {
  [GroupType.StructureGroup]: 'folder',
  [GroupType.Building]: 'business',
  [GroupType.RealEstate]: 'location_city',
  [GroupType.Portfolio]: 'business_center',
  [AddressingSystemSegmentGroupType.AmendParent]: 'subdirectory_arrow_left'
};

export const GroupTypeTranslations = {
  get [GroupType.StructureGroup](): string {
    return Translate.instant(marker('GROUP.GROUP_TYPE.GROUP'));
  },
  get [GroupType.RealEstate](): string {
    return Translate.instant(marker('GROUP.GROUP_TYPE.REAL_ESTATE'));
  },
  get [GroupType.Building](): string {
    return Translate.instant(marker('GROUP.GROUP_TYPE.BUILDING'));
  },
  get [GroupType.Portfolio](): string {
    return Translate.instant(marker('GROUP.GROUP_TYPE.PORTFOLIO'));
  },
  get [AddressingSystemSegmentGroupType.AmendParent](): string {
    return Translate.instant(marker('GROUP.GROUP_TYPE.AMEND_PARENT'));
  }
};
