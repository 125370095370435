/* istanbul ignore file */
/* tslint:disable */

export enum ActionTrigger {
  CHANGE_OF_PROPERTY = 'change_of_property',
  SHAPE_PROPERTY = 'shape_property',
  ON_HOVER = 'on_hover',
  ON_CLICK = 'on_click',
  ON_SLIDE = 'on_slide',
  HAS_LINK_TARGET = 'has_link_target',
  ON_PRESS = 'on_press'
}
